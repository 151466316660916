/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap-datepicker';
import 'chart.js';
import 'chosen-js';
import Cookies from 'js-cookie';
import '@hotwired/turbo-rails';
import jQuery from 'jquery';
import 'jquery-form';
import 'jquery-ui/ui/widgets/sortable';
import '@nathanvda/cocoon';
import '@rails/actiontext';
import Rails from '@rails/ujs';
import 'trix';

import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import 'chosen-js/chosen.css';
import 'trix/dist/trix.css';
import '../stylesheets/index';

global.Cookies = Cookies;

// https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
global.$ = global.jQuery = jQuery;

Rails.start();

// https://stackoverflow.com/a/40672732
jQuery.fn.ready = function (fn) {
  return $(this).on('turbo:load', fn);
};

$(() => {
  $('.datepicker').datepicker({
    format: 'mm/dd/yyyy'
  });
});

function initChosen() {
  $('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'No results matched'
  });
}
$(() => {
  initChosen();
});
$(document).on('turbo:render', () => {
  initChosen();
});
// https://gist.github.com/nsoseka/084cded8bc6d9dc5bdab287c9cb75c88
$(document).on('turbo:before-cache', () => {
  $('.chosen-select').chosen('destroy');
});
